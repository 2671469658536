const panelMain = ["panel1", "panel2", "panel3", "panel4", "panel5", "panel6", "panel7"];
const paragraphMainTitles = [
  "paragraph_title_1",
  "paragraph_title_2",
  "paragraph_title_3",
  "paragraph_title_4",
  "paragraph_title_5",
  "paragraph_title_6",
  "paragraph_title_7",
];

//(If secondary array have length 1, we don't use this array.)-------------|

const panelSecondary = [
  ["panel1.1", "panel1.2", "panel1.3", "panel1.4", "panel1.5", "panel1.6"],
  ["panel2.1"],
  ["panel3.1"],
  ["panel4.1"],
  ["panel5.1"],
  ["panel6.1"],
  ["panel7.1"],
];

const paragraphSecondaryTitles = [
  [
    "paragraph_title_1.1",
    "paragraph_title_1.2",
    "paragraph_title_1.3",
    "paragraph_title_1.4",
    "paragraph_title_1.5",
    "paragraph_title_1.6",
  ],
  ["paragraph_title_2.1"],
  ["paragraph_title_3.1"],
  ["paragraph_title_4.1"],
  ["paragraph_title_5.1"],
  ["paragraph_title_6.1"],
  ["paragraph_title_7.1"],
];

//-------------------------------------------------------------------------|

export const StructurePack = {
  panelMain,
  panelSecondary,
  paragraphMainTitles,
  paragraphSecondaryTitles,
};
